@import "utils";

// resets*
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Bebas Neue";
  user-select: none;
}

main {
  min-height: 100vh;
  min-width: 100vw;
  background: linear-gradient(135deg, #e2ded9 0%, #da7625 100%);

  display: grid;
  place-items: center;
}

.heading {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  position: relative;
}

.heading::before {
  content: "0";
  color: red;
  position: absolute;
  left: -2rem;
  font-weight: 500;
}

.heading::after {
  content: "X";
  color: red;
  position: absolute;
  right: -2rem;
  font-weight: 500;
}

.player-status {
  border: 1px solid #333;
  background: #eee;
  border-radius: 1rem;
  padding: 0.5rem 3rem;
}

.current-player {
  color: red;
  font-weight: 500;
}
// GAME GRID
.game-grid {
  background: orange;
  width: 20rem;
  aspect-ratio: 1/1;
  border-radius: 1rem;

  background: linear-gradient(145deg, #f7f0f0e3, #fffffffa);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fffffff1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 2rem;
}

.box {
  border: 1px solid #333;
  display: grid;
  place-items: center;
  font-size: 3rem;
  font-weight: 500;
  color: #333;
  padding: 1rem;
  cursor: pointer;
}

.btn-reset {
  cursor: pointer;
  background: whitesmoke;
  border: 1px solid #333;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  visibility:hidden;
}

.active {
  visibility: visible;
}

.green {
  background: greenyellow;
}

@media screen and (max-width: 450px) {
  .heading {
    font-size: 1rem;
    letter-spacing: 0;
  }

  .heading::after {
    right: -1rem;
  }

  .game-grid {
    width: 15rem;
  }

  .box {
    font-size: 4rem;
  }
}
